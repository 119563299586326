import { IconIds, IconProps } from '@/components/atoms/legacy/Icon';

type Maybe<T> = T | null;

export type Untrusted<T> = {
  [P in keyof T]?: Untrusted<T[P]>;
};

export type TrustedType<T> = {
  [P in keyof T]: NonNullable<T[P]>;
};

export interface CalculatorFuelSavingsProps {
  timePeriod?: string;
  fuelSavingsEstimate?: string;
  annualMiles?: string;
  gasPerGallon?: string;
  electricityPerKwh?: string;
}

export type CalculatorType = 'vdp' | 'homepage' | 'vlp';
export interface PaymentCalculatorProps {
  type: CalculatorType;
  salePrice: number;
  setSalePrice?: (salePrice: number) => void;
  onChangeMonthlyEstimatedPayment?: (monthlyEstimatedPayment: number) => void;
  onChangeTerm?: (termMonths: string) => void;
  handleOfferButton?: () => void;
  incentivesTotal?: number;
  fuelSavings?: CalculatorFuelSavingsProps;
  expanded?: boolean;
  setExpanded?: (expanded: boolean) => void;
  handleSearch?: () => void;
}

export interface CalculatorInstanceProps extends PaymentCalculatorProps {
  mode: 'Finance' | 'Lease';
  setMode: (mode: 'Finance' | 'Lease') => void;
}

export interface PositionedHeroIconProps {
  icon: JSX.Element;
  position?: 'left' | 'right' | 'top' | 'bottom';
  className?: string;
}
export interface BaseButtonProps
  extends Pick<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    'onClick' | 'disabled' | 'type' | 'onMouseEnter' | 'onMouseLeave'
  > {
  icon?: PositionedHeroIconProps;
  analyticsEvent?: AnalyticsEvent;
  'aria-label': string;
}

export function isDropdownMenuGroups(
  options: DropdownMenuOptionType[] | DropdownMenuGroupType[]
): options is DropdownMenuGroupType[] {
  const groupOptions = options as DropdownMenuGroupType[];
  return groupOptions.every((option) => 'category' in option);
}

export interface MakeIconProps {
  /** An uppercase string of the make of the car */
  make: string;
  color?: boolean;
  width?: number;
  height?: number;
  className?: string;
  'aria-label'?: string;
  /** If `true`, fades in a colored gradient border on hover. Default `false`.
   * Also uses a different `className` value. The `width` and `height` of the icon need to be 48 for this to "work".
   */
  withOverlay?: boolean;
}

export type DropdownMenuOptionType = {
  text: string;
  value: string;
  makeIcon?: MakeIconProps;
  count?: number;
};
export type DropdownMenuGroupType = {
  category: string;
  index: number;
  menuItems: DropdownMenuOptionType[];
};

export type FilterChip = {
  filterName: keyof FilterType;
  label: string;
  onClose: () => void;
};

export interface Location {
  name: string;
  radius?: string;
  postalCode: string;
}
export type City = Location;
export type State = Location;

type RawBatteryInfo = {
  size?: string;
  tech?: string;
  warranty?: string;
};

type RawChargingInfo = {
  ChargingTime120V: string;
  ChargingTime240V: string;
  ChargingTimeDC: string;
  ChargingPeakRateDC: string;
  ChargingConnectorDC: string;
};
export type RawListingTypeMinimal = {
  DealerId: string;
  ListingID: string;
  StockNumber: string;
  Mileage?: { unit: string; value: number };
  Price: number | { unit: string; value: number | null };
  Dealership: string;
  DealerGroupName?: string;
  Makes?: string[];
  Address: string;
  City: string;
  State: string;
  Postal: string;
  Phone: string;
  Email: string[];
  Photo?: string;
  Photos?: string[];
};

export type RawListingType = RawListingTypeMinimal & {
  Vin: string;
  CompanyID?: string;
  Desc: string;
  PhotoTimeStamps: string[];
  Warranty: number;
  WarrantyDescription: string;
  FinanceDownPayment: string;
  FinanceLeaseKilometers: string;
  FinanceNumberOfPayments: string;
  FinancePaymentType: string;
  MFGCertificationProgram: string;
  AdLastModifiedDate: string;
  CategoryId: string;
  created_at: string;
  updated_at: string;
  distance?: number;
  State?: string;
  Scraped?: boolean;
  URL?: string;
  Location?: string;
  IsActive?: boolean;
  Photos: string[];
};

export type VehicleIncentives = 'Federal';
export enum VehicleBadgeIds {
  'ELIGIBLE FOR FEDERAL INCENTIVES' = 'Qualifies for incentives',
}
export type RawBatteryHealth = {
  BatteryHealthEstimate: number;
};

export type RawVehicleListingBadge = VehicleListingBadge | undefined;
export type VehicleListingBadge = keyof typeof VehicleBadgeIds;
export type RawVehicleType = {
  Vin: string;
  Badges: RawVehicleListingBadge[];
  Battery?: RawBatteryInfo;
  BatteryWarranty: string;
  Charging: RawChargingInfo;
  Year: number;
  Make: string;
  Model: string;
  Trim: string;
  FuelType: string;
  Drivetrain: string;
  Condition: string;
  Transmission: string;
  Doors: string;
  NoOfPassengers: string;
  InteriorColor: string;
  IntColorHexCode: string;
  IntColorCode: string;
  ExteriorColor: string;
  ExtColorHexCode: string;
  ExtColorCode: string;
  Seats: string;
  Drive: string;
  Performance: string;
  EngineSize: string;
  Cylinders: string;
  Body: string;
  Options: string[];
  Features: { id: string; name: string }[];
  created_at: string;
  updated_at: string;
  listing: RawListingType;
  RangeAllElectric?: ValueWithUnit;
  RangeHybridElectric?: ValueWithUnit;
  hasModelLevelPage: boolean;
  Incentives: { [key in VehicleIncentives]: number } | null;
  ShortListingID: string | null;
  StyleId?: number;
  incentives?: IncentiveResponse;
  s3_photos: string[];
  style: string;
  gas_rate: number;
  ev_rate: number;
  priceHistory: PriceHistory[];
  vehicleDetails: RawTrimSpecs;
  isSaved: boolean;
};

export type RawVehicleListingType = Untrusted<{
  Vin: string;
  Make: string;
  Model: string;
  Year: number;
  Trim: string;
  Distance: string;
  Dealer: DealerType | null;
  Price: DisplayValue;
  FuelType: string;
  Drivetrain: string;
  Incentives: { [key in VehicleIncentives]: number } | null;
  incentives: IncentiveResponse;
  ShortListingID: string | null;
  Badges: RawVehicleListingBadge[];
  Mileage: ValueWithUnit;
  Battery: RawBatteryInfo;
  Body: string;
  RangeAllElectric?: ValueWithUnit;
  RangeHybridElectric?: ValueWithUnit;
  StockNumber: string;
  listing: RawListingTypeMinimal | RawListingTypeMinimal[];
  StyleId?: number;
  s3_photo: string;
  s3_photos: string[];
}>;

export type EfficiencyRates = {
  vehicle_style: string;
  gas_rate: number;
  ev_rate: number;
};

export type IncentiveResponse = {
  State: JDPowerIncentive[];
  Federal: JDPowerIncentive[];
  federalTotal: number;
  last_updated_at: string;
  local: JDPowerIncentive[];
  localTotal: number;
  stateTotal: number;
  styleId: number;
  vin: string;
  zipCode: number;
};

export interface VehiclePreview {
  year: number;
  make: string;
  model: string;
  trim: string;
}

export interface MinimalVehicle extends VehiclePreview {
  listingId: string;
  vin?: string;
  year: number;
  make: string;
  images: string[];
  model: string;
  trim: string;
  body: string;
  dealerName?: string;
  price: DisplayValue;
  badges: VehicleListingBadge[];
  calculatedIncentives: { [key in VehicleIncentives]: number } | null;
  shortListingId: string | null;
}

export interface VehicleType extends Omit<MinimalVehicle, 'dealerName'> {
  vin: string;
  fuelType: string;
  condition: string;
  options: string[];
  features: string[];
  stockNumber: string;
  dealer: DealerType | null;
  mileage?: ValueWithUnit;
  description: string;
  imageTimestamps: string[];
  distance: string;
  overviews: SpecificationType[];
  performances: SpecificationType[];
  chargingInfo: ChargingInfoType;
  batteryInfo: BatteryInfoType;
  rangeInfo: Range;
  scraped: boolean;
  url?: string;
  location?: string;
  hasModelLevelPage: boolean;
  interiorColor: string;
  interiorColorCode: string;
  interiorColorHex: string;
  exteriorColor: string;
  exteriorColorCode: string;
  exteriorColorHex: string;
  seats: string;
  drivetrain: string;
  horsepower: string | null;
  batteryWarranty: string;
  listingWarranty: {
    value: string;
    description: string;
  };
  styleId?: number;
  incentives?: IncentiveResponse;
}

export type VehicleListingType = {
  vin: string;
  make: string;
  model: string;
  year: number;
  trim: string;
  dealer: DealerType | null;
  images: string[];
  price: DisplayValue;
  fuelType: string;
  drivetrain: string;
  incentives: IncentiveResponse;
  calculatedIncentives: { [key in VehicleIncentives]: number } | null;
  styleId?: number;
  listingId: string;
  shortListingId: string | null;
  badges: VehicleListingBadge[];
  body: string;
  mileage: ValueWithUnit;
  batteryInfo: BatteryInfoType;
  rangeInfo: Range;
  stockNumber: string;
};

export type AnalyticsEvent = {
  eventName: string;
  conversionLabel: string;
  eventLabel: string;
  pageTarget?: string;
  username?: string;
  zip?: { city: string; country: string };
  customProperties?: Record<string, string>;
};

export type CoordsType = {
  latitude: number;
  longitude: number;
};

export type SortedHighlightsBadges = {
  primary: HighlightsBadge[];
  secondary: HighlightsBadge[];
};

export type VehicleTypeWithTrimSpecs = Omit<VehicleType, 'features'> & {
  trimSpecs: TrimSpecs;
  highlightBadges: SortedHighlightsBadges | null;
};

export type GeneralRawDealerType = {
  City: string;
  State: string;
  Dealership: string;
  Phone: string;
  DealerID: string;
  PostalCode: string;
};

export type RawDealerType = {
  Address: string;
  City: string;
  Country: string;
  DealerGroupName: string;
  DealerId: string;
  Dealership: string;
  Email: string[];
  Makes: string[];
  Phone: number;
  Postal: number;
  State: string;
  Benefits: string[];
};

export type DealerType = {
  dealerID: string;
  name: string;
  groupName: string;
  makes: string[];
  address: string;
  city: string;
  province: string;
  postalCode: string;
  phone: string;
  email: string[];
};

export type LinkType = {
  text: string;
  href: string;
  icon?: IconIds;
  onClick?: (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
};

export type OptionType = {
  text?: string;
  value: string;
  icon?: IconProps;
};

export type ColorOptionType = {
  hex: string;
  value: string;
};

export type FilterState = {
  shouldRefetch: boolean;
  fields: FilterType;
};

export type DistanceFilterValue = '50' | '100' | '150' | '200' | 'nationwide';

export type FilterType = {
  postal: string;
  distance: DistanceFilterValue;
  condition: string;
  fuelType: string;
  year_start: string;
  year_end: string;
  price_start: string;
  price_end: string;
  make: string[];
  model: string[];
  skip: number;
  limit: number;
  radius_km: string;
  maxMileage: string;
  sort: string;
  dealer_group: string;
  body: string[];
  features: string[];
  has_ev_incentives: boolean;
  vector_search: string;
};

export type FetchListingQueryStringParams = {
  make?: string;
  model?: string;
  condition?: string;
  year_start?: string;
  year_end?: string;
  price_start?: string;
  price_end?: string;
  range_start?: string;
  range_end?: string;
  fuel_type?: string;
  max_mileage?: string;
  postal?: string;
  radius_km?: string;
  sort?: string;
  skip?: number;
  limit?: number;
  has_ev_incentives?: boolean;
  body?: string;
};

export type SpecificationType = {
  title: string;
  text: string;
};

export type ContactDealerFormType = {
  name: string;
  phone: string;
  email: string;
  message: string;
};

export type ContactSupportFormType = {
  name: string;
  phone: string;
  email: string;
  message: string;
  subscribe: boolean;
};

export type SubscribeFormType = {
  email: string;
};

export type RequestDemoFormType = {
  name: string;
  phone: string;
  email: string;
  dealer: string;
};

export type ChargingInfoType = {
  isEV?: boolean;
  level1: string;
  level2: string;
  level3: string;
  peakChargeRate: string;
  chargingConnectorDC: string;
};

export type BatteryInfoType = {
  batteryTech: string;
  batterySize: string | null;
  batteryWarranty: string | null;
  batteryHealthEstimate?: number | null;
};

export type RawNewEVModel = {
  Year: number;
  Make: string;
  Model: string;
  ModelId: number;
  Range: {
    Value: number;
    Unit: string;
  };
  MSRP: number;
  MainImage?: string;
  hasModelLevelPage?: boolean;
  StyleId: number;
};

export type NewEVModel = {
  year: number;
  make: string;
  model: string;
  modelId: string;
  range: {
    value: number;
    unit: string;
  };
  msrp: number;
  mainImage: string;
  incentives: JDPowerIncentive[];
  maxIncentive: number;
  hasModelLevelPage: boolean;
};
export type Incentive = {
  amount: number;
  name: string;
  fundingSource: string;
  summary: string;
  disclaimer: string;
  externalSource: string;
  questionnaire?: {
    [key: string]: {
      name: string;
      description: string;
      type: string;
      selections: {
        [status: string]: IncentiveQuestionnaireSelection;
      }[];
    };
  };
};

export type IncentiveQuestionnaireSelection = {
  [income: string]: number;
};
export type IncentivesResponse = {
  content: Incentive[];
  size: number;
  state: string;
};

export type RawRange = RawValueWithUnit;

export type Range = {
  value: number;
  unit: string;
};

export type ExtendedOptionsFields = {
  code: string;
  msrp: number;
  invoice: number;
  description: string;
  mandatory?: boolean;
};

export type ExtendedOptions = {
  [key: string]: ExtendedOptionsFields[];
};

export type RawTrim = {
  _id: number;
  Year: string;
  Make: string;
  Model: string;
  ModelId: string;
  MainImage: string;
  Trim: string;
  FuelType: string;
  Range: null | RawRange;
  RangeAllElectric: null | RawRange;
  RangeHybrid: null | RawRange;
  MSRP: number;
  'HorsePower@RPM': string;
  Drivetrain: string;
  StyleId: number;
  BodyType: string;
};

export type RawTrimDetails = RawTrim & {
  ExteriorImages: {
    [code: string]: RawImageObject;
  };
  InteriorImages: RawImageObject['Images'];
  InteriorColors: {
    ColorName: string;
    MSRP: number;
    RGB: string | null;
  }[];
  RangeHybridElectric: RawRange | null;
  Range: RawRange | null;
  ExtendedOptions?: {
    [key: string]: ExtendedOptionsFields[];
  };
  IncentivesTotal?: number;
  Incentives?: JDPowerIncentive[];
  FederalTaxCredit?: number;
};

export type TrimStyle = {
  year: string;
  make: string;
  model: string;
  modelId: string;
  trim: string;
  trimId: number;
  fuelType: string;
  rangeAllElectric: null | Range;
  rangeHybrid: null | Range;
  range?: Range | null;
  msrp: number;
  horsepower: string;
  exteriorImages: ImageObject[];
  interiorImages: ImageObject['images'];
  interiorColors: ColorObject[];
  noOfPassengers?: string;
  extendedOptions?: {
    [key: string]: ExtendedOptionsFields[];
  };
  incentivesTotal: number | null;
  incentives: JDPowerIncentive[];
  federalTaxCredit: number;
  drivetrain: string;
};

export type RawImageObject = {
  ColorName: string;
  MSRP: number;
  RGB: string;
  Images: {
    href: string;
    ShotCode: string;
    ShotDest: string;
    Background: string;
    Width: number;
    Height: number;
  }[];
};

export type ImageObject = {
  code: string;
  rgb: string;
  colorName: string;
  msrp: number;
  images: {
    href: string;
    shotCode: string;
    shotDest: string;
    background: string;
    width: number;
    height: number;
  }[];
};

export type ColorObject = {
  colorName: string;
  msrp: number;
  rgb: string | null;
};

export type ModelPageState = {
  model: string;
  year: string;
};

export type MakePageState = {
  location: string;
  make: string;
};

export type TrimPageState = {
  id?: string;
  trim: string;
  exteriorName: string;
  interiorColorName: string;
  extendOptionCodes: string[];
};

export type BuildResult = {
  id: string;
  trim: TrimStyle;
  exteriorColor: ImageObject;
  interiorColor: ColorObject;
  extendedOptions: ExtendedOptionsFields[];
  location: string;
  federalTaxCredit: number;
};

export type MinimalGenerateLeadRequest = {
  full_name?: string;
  customer_email?: string;
  phone?: string;
  message?: string;
};

export type ListingEmailInfo = MinimalGenerateLeadRequest & {
  listing_id: string;
  listing_url?: string;
  stock_number: string;
  token?: string;
  additional_info: {
    dealership?: string;
    vin?: string;
    listing_id: string;
    stockNumber: string;
    modelImgUrl: string;
    year: number;
    make: string;
    model: string;
    trim: string;
    base_msrp: number | string;
    total_savings: number;
    total_msrp: number | string;
    mileage?: ValueWithUnit;
    range: Range;
    ip_address?: string;
    fuel_type: string;
    drivetrain: string;
    federal_tax_credit: number;
    point_of_sale_rebates: { name: string; value: number }[];
    afterwards_rebates: { name: string; value: number }[];
    est_fuel_savings: number;
  };
};

export type BuildInfo = MinimalGenerateLeadRequest & {
  location: string;
  listing_url?: string;
  vehicle: {
    id: string;
    price: number;
    year: string;
    make: string;
    model: string;
    model_id: string;
    model_name: string;
    image: string;
    trim: string;
    trim_id: number;
    exterior_name: string;
    exterior_msrp: number;
    interior_name: string;
    interior_msrp: number;
    extended_options: ExtendedOptionsFields[];
    base_msrp: number;
    federal_tax_credit: number;
    total_savings: number;
    total_msrp: number;
    fuelType: string;
    drivetrain: string;
    range: Range | null;
  };
};

export type ChatInfo = {
  message: string;
  conversation_id: string;
};

export type HighlightName =
  | 'Mileage'
  | 'Est. Range'
  | 'Battery capacity'
  | 'Seats'
  | 'Drivetrain'
  | 'Performance'
  | 'Fast charger'
  | 'Exterior color'
  | 'Interior color'
  | 'VIN'
  | 'Warranty'
  | 'Battery warranty'
  | 'Charging type'
  | 'Wheel size'
  | 'Cargo capacity'
  | 'Fast charger'
  | 'Smart device integration'
  | 'Autonomy'
  | 'Year'
  | 'Additional feature';

export interface ModelLevelDetailTemplateProps
  extends ModelLevelDetails,
    IndividualSavedVehicleProps {
  trims: TrimStyleWithSpecs[];
  images: ImageSet;
  showNewMlpFeatures: boolean;
  savedVehicles: SavedVehicles;
}
export type AvailableColor = {
  genericDesc: string;
  description: string;
  colorCode: string;
  rgbValue: string;
  rgbHexValue: string;
  type: number;
  primary: boolean;
  installCause: string;
};

export type ModelLevelDetails = {
  id: number;
  year: string;
  make: string;
  model: string;
  startingMSRP: string;
  maxHorsepower: string;
  maxRange: Range;
  availableColors: AvailableColor[];
  minBatteryCapacity: number;
  maxBatteryCapacity: number;
  batteryWarranty: string;
  maxDCFastCharging: number;
  highlights: SortedHighlightsBadges | null;
};

export type Feature = {
  Name: string;
  Value: number | string;
};

export type RawValueWithUnit = {
  Value: number;
  Unit: string;
};

type TrimSpecDetail = {
  id: string;
  name: string;
  value: string;
  installCause: InstallCause;
};

type InstallCause = 'S' | 'R';

type RawRating = {
  Economy_Rating: number;
  Family_Friendly_Rating: number;
  Performance_Rating: number;
  Safety_Rating: number;
  Tech_Rating: number;
};

interface BaseHighlights {
  Body_Type: string;
  Seating_Capacity: number;
  Drive_Type: string;
  Battery_Warranty: string;
  Charger_Plug_Type: string;
}

interface RawHighlights extends BaseHighlights {
  DC_Charging_Time: RawValueWithUnit;
  Cargo_Capacity: RawValueWithUnit;
  Range: RawValueWithUnit;
  Horsepower: RawValueWithUnit;
  Torque: RawValueWithUnit;
  Wheel_Size: RawValueWithUnit;
}

interface Highlights extends BaseHighlights {
  DC_Charging_Time: ValueWithUnit;
  Cargo_Capacity: ValueWithUnit;
  Range: ValueWithUnit;
  Horsepower: ValueWithUnit;
  Torque: ValueWithUnit;
}

export type HighlightsBadge = {
  name: HighlightName;
  description: string;
  extraInfo?: { [key: string]: string };
};

export type RawTrimSpecs = {
  Badges: string[];
  Est_MSRP: number;
  More_Details: { [key: string]: TrimSpecDetail[] };
  Rating: RawRating;
  Highlights?: RawHighlights;
};

export type ValueWithUnit = {
  value: number | string;
  unit?: string;
};

/** For values where you need to apply some operations on it as if it were a number.
 * @see VehicleType['price'].
 */
export type DisplayValue = {
  value: number | null;
  displayValue: string;
};

export type TrimSpecs = {
  msrp: number;
  moreDetails: { [key: string]: TrimSpecDetail[] };
  rating: Rating | null;
  electricMileage: {
    energy: ValueWithUnit;
    distance: ValueWithUnit;
  };
  specWarranty: {
    distance: ValueWithUnit;
    time: ValueWithUnit;
  };
  batteryWarranty: {
    distance: ValueWithUnit;
    time: ValueWithUnit;
  };
  highlights: Highlights;
};

export type Rating = {
  economyRating: number;
  familyFriendlyRating: number;
  performanceRating: number;
  safetyRating: number;
  techRating: number;
};

export type TrimStyleWithSpecs = TrimStyle & {
  specs: TrimSpecs;
  image?: string;
};

export type DisplayImageNames =
  | 'GalleryImages'
  | 'ExteriorImages'
  | 'InteriorImages';

export type RawImageSet =
  | {
      DefaultImages: string[];
    }
  | {
      [key in 'GalleryImages' | 'ExteriorImages' | 'InteriorImages']: string[];
    };

export type ImageSet = {
  DefaultImages: string[];
  GalleryImages: string[];
  ExteriorImages: string[];
  InteriorImages: string[];
};

export type BasePhotoFilterLabel = 'Lifestyle' | 'Exterior' | 'Interior';
export type PhotoFilterLabel = BasePhotoFilterLabel | 'All';

export type DisplayImage = {
  imageType: keyof ImageSet;
  src: string;
};

export interface SelectedModelsProps extends VehiclePreview {
  imageURL: string;
}

export interface UserPreview {
  email: string;
  fullName: string;
  phone: Maybe<string>;
  evId: string;
  picture?: string;
}

export interface MinimalUser extends UserPreview {
  firstName: Maybe<string>;
  lastName: Maybe<string>;
  identityProvider: Maybe<string>;
}

export type StoreField =
  | 'build'
  | 'model_level_page'
  | 'listing'
  | 'incomplete_build';

export type SavedVehicles = Record<StoreField, Record<string, RawSavedVehicle>>;

export interface IndividualSavedVehicleProps {
  isSaved: boolean;
}

export interface SavedVehiclesListProps {
  savedVehicles: SavedVehicles | null;
}

export interface RawSavedVehicle {
  id: string;
  price: string;
  year: number;
  make: string;
  model: string;
  link: string;
  image: string;
  contact_dealership?: boolean;
  created_at: string;
}

interface BaseSavedVehicle {
  year: number;
  make: string;
  image: string;
  model: string;
  trim?: string;
  dealerName?: string;
  price: string;
}

export interface SaveVehicleRequest extends BaseSavedVehicle {
  id: string | number;
}
export interface MinimalSavedVehicle extends BaseSavedVehicle {
  id: string;
}

export interface SavedVehicle extends MinimalSavedVehicle {
  expired: boolean;
  contactedDealer: boolean;
  storeField: StoreField;
}

export type BasePageProps<T = object> = T & {
  user?: MinimalUser | null;
  noHeader?: boolean;
  noFooter?: boolean;
  _nextI18Next?: unknown; // TODO: Add a better type for this later when we actually use i18n throughout the app
};

export interface JDPowerIncentive {
  id: number;
  grantor: string;
  name: string;
  evaluation: { amount_in_purchase: number };
  point_of_sale: boolean;
  max_amount: number | null;
  mutually_exclusive_programs: string[];
  details_url: string;
  description: string;
}

// TODO: MVB-1103 Update with types from Amplify v6 once we upgrade
export interface MinimalCognitoUser {
  username: string;
  attributes: {
    email: string;
    phone_number: string;
    sub: string;
    /** Is the user's first name and last name or their first name by default. */
    given_name?: string;
    /** The user's first name. */
    name?: string;
    family_name?: string;
    identities: { providerName: string }[] | null;
  };
}

export interface PriceHistory {
  value: number;
  unit: string;
  date: Date;
}

export interface BodyTypesByMakes {
  [make: string]: {
    listed: string[];
    unlisted: string[];
    build: string[];
  };
}
